$player-height: 9rem;

.evp-player {
  $player: &;
  height: $player-height;

  .container {
    flex-wrap: wrap;
  }

  .evp-break-column {
    flex-basis: 100%;
    width: 0;
    height: 1px;
    background: $black;
    margin: .25rem 0;
  }

  &__info {
    max-width: fit-content;
  }

  &__toggle {
    right: 0;
    top: 1.25rem;
  }

  &__embed {
    visibility: hidden;
    height: 0;
    bottom: 0;
    transform: translate(0, $player-height * -1) !important;
    width: 100% !important;

    @at-root #{$player}_is-expanded & {
      visibility: visible;
      transition: all .2s;
      height: 250px;
    }
  }
}
