$header-profile-height: 10.5rem;

.evp-header {
  padding-top: 2rem;
}

.evp-profile {
  max-height: $header-profile-height;
  width: fit-content;

  &__avatar {
    $height: 9.75rem;
    $width: 8.25rem;

    height: $height;
    position: relative;
    width: $width;

    &:before {
      background-size: $height * 0.923;
      height: $height;
      top: -.5rem;
      width: $width;
    }
  }

  &__info {
    background-size: 100%;
    height: 2.5rem;
    width: 11rem;
  }
}
