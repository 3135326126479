.evp-exp {
  $exp: &;

  &__col {
    &_spaze #{$exp}__box,
    &_sehack #{$exp}__box {
      .img-thumbnail {
        width: 100% !important;
      }
    }
  }

  &__box {
    padding-bottom: 75% !important;
  }
}
