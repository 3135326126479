@import "../variables";

.evp-exp {
  $exp: &;
  $box-items-content-transition: (
    "iframe-new-tab": (
      left: 36%,
      right: initial,
    ),
    spaze: (
      left: initial,
      right: 32%,
    ),
    sehack: (
      left: initial,
      right: 32%,
    ),
  );

  &__bottom {
    height: 100%;
  }

  &__grid {
    height: calc(100% - $exp-nav-height);
  }

  &_hidden {
    transform: translateY(-500%);
    visibility: hidden;
  }

  &__desc {
    text-align: right;

    span {
      display: block;
      font-size: .9rem;
    }
  }

  &__grid {
    $grid: &;
    border-width: 1rem 0;
    transition: all .2s;

    &_pets {
      transform: translateY(100%);
    }
  }

  &__col {
    &_socialbet #{$exp}__box,
    &_ignet #{$exp}__box,
    &_spaze #{$exp}__box,
    &_sehack #{$exp}__box {
      margin-top: .5rem;
      position: relative;
    }

    &_spaze picture {
      margin: 0 0 0 auto;
    }
    &_sehack picture {
      margin: 0 auto 0 0;
    }

    &_spaze, &_sehack {
      margin-right: 0;

      picture {
        width: 50%;
      }

      a .img-thumbnail {
        height: 85% !important;
      }
    }

    @each $name, $transition in $exp-routes {
      .evp-h-#{$name} &_#{$name} {
        transform: translateY(500%);
      }
    }
  }

  &__col + &_hidded {
    visibility: visible;
  }

  &__box {
    padding-bottom: 50% !important;

    .img-thumbnail {
      opacity: .75;
      filter: grayscale(50%);
    }

    &-desc {
      bottom: 0;
      white-space: normal;
      background: none;
      box-shadow: none;
    }

    &-title {
      span {
        background: $primary-bg-gradient;
        display: inline-block;
        font-size: .85rem;
        margin: .15rem .05rem;
        padding: .25rem .5rem;
        width: fit-content;

        &:first-child {
          display: block;
        }
      }
    }

    &-role, &-more {
      display: none;
    }
  }

  &__content {
    left: 0 !important;
    right: 0 !important;
    top: 200%;

    &__text {
      height: 100%;
      span {
        line-height: 1rem;
      }
    }

    &-scrollable {
      height: 35%;

      &-inner {
        line-height: 1.3rem;
      }
    }

    &-thumbnail, &-desc {
      display: block;
    }

    @each $name, $props in $box-items-content-transition {
      .evp-h-#{$name} & {
        &--#{$name} {
          @each $prop, $value in $props {
            #{$prop}: #{$value};
          }
        }
      }
    }
  }
}

@each $name in $exp-routes {
  .evp-h-#{$name} {
    .evp-exp__top {
      height: 0;
      transition: height .2s;
    }
  }
}
