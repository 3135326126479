.evp-gallery {
  &__col {
    &_sq {
      height: 250px;
    }
    &_v {
      height: 375px;
    }
  }
}
